import { Col, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createquan } from "../features/quan/QuanSlice";
import axios from "axios";
import { option4 } from "../data/data";

const CreateQuan = () => {
  const navigate = useNavigate();
  const [selectedTinh, setSelectedTinh] = useState(null);
  const [quan, setQuan] = useState([]);
  const [quan1, setQuan1] = useState([]);
  const [names, setNames] = useState([""]); // Initialize with an empty array for names

  const dispatch = useDispatch();
  const userSchema = Yup.object().shape({
    name: Yup.array().of(Yup.string().required("Yêu cầu nhập tên sân")),
    quan: Yup.string().required("Yêu cầu nhập quận"),
    city: Yup.string().required("Yêu cầu nhập thành phố"),
  });

  const user = useSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      city: "",
      quan: "",
      name: [""],
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (!user) {
        toast.warning("Bạn chưa đăng nhập");
        return;
      }
      dispatch(createquan(values));
      toast.success("Đăng bài thành công 😆");
      setTimeout(() => {
        formik.resetForm();
        setNames([""]);
        navigate(-1);
      }, 300);
    },
  });

  useEffect(() => {
    const url = "https://esgoo.net/api-tinhthanh/4/0.htm";
    axios.get(url).then((response) => {
      if (response.data.error === 0) {
        setQuan1(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedTinh) {
      const url = `https://esgoo.net/api-tinhthanh/2/${selectedTinh}.htm`;
      axios.get(url).then((response) => {
        if (response.data.error === 0) {
          setQuan(response.data.data);
        }
      });
    }
  }, [selectedTinh]);

  const handleNameChange = (index, event) => {
    const newNames = [...names];
    newNames[index] = event.target.value;
    setNames(newNames);
    formik.setFieldValue("name", newNames);
  };

  const addNameField = () => {
    setNames([...names, ""]);
  };

  const removeNameField = (index) => {
    const newNames = names.filter((_, i) => i !== index);
    setNames(newNames);
    formik.setFieldValue("name", newNames);
  };

  return (
    <Container className="py-5 w-full">
      <div className="border w-full rounded-3 p-4">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="text-center fw-bold mb-3 fs-4 block">
            Nhập danh sách sân
          </span>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3"
          style={{ flexDirection: "column" }}
        >
          <Select
            placeholder="Thành phố"
            value={formik.values.city}
            onChange={(value) => {
              formik.setFieldValue("city", value);
              setSelectedTinh(
                value === "Thành phố Hà Nội"
                  ? "01"
                  : value === "Thành phố Hồ Chí Minh"
                  ? "79"
                  : "00"
              );
            }}
            options={option4}
            style={{ width: "100%" }}
          />
          <Select
            placeholder="Quận"
            value={formik.values.quan}
            onChange={(value) => {
              formik.setFieldValue("quan", value);
            }}
            options={
              selectedTinh === "01" || selectedTinh === "79"
                ? quan.map((q, index) => ({
                    value: q.full_name,
                    label: q.full_name,
                    key: `${q.full_name}-${index}`,
                  }))
                : quan1
                    .flatMap((item) => item.data2)
                    .map((q, index) => ({
                      value: q?.full_name,
                      label: q?.full_name,
                      key: `${q?.full_name}-${index}`,
                    }))
            }
            style={{ width: "100%" }}
          />
          {names.map((name, index) => (
            <div key={index} className="d-flex gap-2 align-items-center">
              <Input
                placeholder={`Nhập tên sân ${index + 1}`}
                name={`name-${index}`}
                value={name}
                onChange={(event) => handleNameChange(index, event)}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {names.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeNameField(index)}
                  className="btn btn-danger"
                >
                  Xóa
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            style={{ width: "max-content" }}
            onClick={addNameField}
            className="btn btn-primary mb-3"
          >
            Thêm tên sân
          </button>
          {formik.touched.name && formik.errors.name && (
            <div className="text-danger">{formik.errors.name}</div>
          )}
          <div
            style={{ width: "100%", justifyContent: "end", display: "flex" }}
          >
            <button
              type="submit"
              style={{ width: "200px" }}
              className="btn btn-warning"
            >
              Đăng tin
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default CreateQuan;
